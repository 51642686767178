<template>
  <FormComponent @submit="resetPassword">
    <h3 class="auth-title">
      <button class="btn" type="button" @click="back()">
        <span class="material-icons-outlined">arrow_back</span>
      </button>
      {{ $t('app.reset_password') }}
    </h3>
    <InputComponent v-model="form.email" :label="$t('reset-password.email')"
                    :placeholder="$t('app.email')"
                    name="email" required
                    type="email"></InputComponent>
    <ButtonComponent :loading="isSending" is-block type="submit">
      {{ $t('app.reset_password') }}
    </ButtonComponent>
  </FormComponent>
</template>

<script>
import FormComponent from '@/components/form/FormComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'ResetPassword',
  components: { ButtonComponent, InputComponent, FormComponent },
  data() {
    return {
      isSending: false,
      form: {
        email: '',
      },
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    resetPassword() {
      this.isSending = true;
      this.$store.dispatch('auth/reset', this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('warnings.reset-password.instruction'),
            type: 'success',
          });
        },
        (error) => {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.error-occur'),
            type: 'danger',
          });
        },
      ).finally(() => {
        this.isSending = false;
      });
    },
  },
};
</script>
